/*
TODO: Refactor this middleware and Login logic
- prevent running every time a route changes
*/

export default defineNuxtRouteMiddleware(async to => {
  const authStore = useAuthStore()
  const { user } = authStore
  if (user) {
    return
  }
  try {
    const reportGroups = ['Client', 'Stakeholder']
    if (!reportGroups.includes(db_user.group)) {
      return navigateTo('/')
    }
    return // allow access to reports
  } catch {
    return to.path === '/login' ? undefined : navigateTo('/')
  }
})
